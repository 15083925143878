import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-yellow-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full `
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background:url("https://images.unsplash.com/photo-1592093474530-86874167e896?q=80&w=2002&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-attachment: fixed;
  `;
  
  // background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`  text-3xl text-center sm:text-3xl lg:text-4xl xl:text-5xl text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw` text-4xl text-center sm:text-4xl lg:text-5xl xl:text-6xl inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-12 py-3 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 bg-yellow-600  font-bold shadow transition duration-300  text-gray-100 hocus:bg-yellow-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <Content>
          <Heading>
            <span  style={{color:"yellow", fontWeight:"900"}}>
            Karmik Upaye
            </span>
            <br/>
            Har pareshani ka upaye
          </Heading>
          <h2 style={{color:'white'}}>An initiative by AdornTalks
          </h2>
          {/* <PrimaryAction >Book now</PrimaryAction> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
