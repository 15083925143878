import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";import Footer from 'components/footers/SimpleFiveColumn';

import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <div style={{background:'white'}}>
      <Header />
      <ContactUsForm />
      {/* <ContactDetails
        cards={[
          {
            title: "Mobile Number",
            description: (
              <>
              <Phone>+1 (203) 991-6988</Phone>
              </>
              )
            },
            {
              title: "Whatsapp",
              description: (
                <>
                <Phone>+1 (203) 991-6988</Phone>
                </>
                )
              },
              {
                title: "Email",
                description: (
                  <>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          },
      ]
    }
  /> */}
      <Footer />
  </div>
    </AnimationRevealPage>
  );
};
