import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from 'components/footers/SimpleFiveColumn';
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
// import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex justify-center `;
const Heading = tw(SectionHeading)`text-gray-900 mb-10 text-center `;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose px-3` }
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <MainFeature1 
        // subheading={<Subheading>About Karmic Upaye</Subheading>}
        heading="About Karmik Upaye"
        buttonRounded={true}
        primaryButtonText="See Portfolio"
        
        // imageSrc="https://images.unsplash.com/photo-1515942661900-94b3d1972591?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D"
      />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
        ]}
        linkText=""
      /> */}
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading >About Us</Heading>
          </HeadingRow>

          <Text>
            <p>
              Every Day we come across different kinds of challenges in our lives! You might be facing difficulties or stress somewhere in life! & You are looking for an authentic solution, here is the solution to your every problem.
              <br /> Our experts are available 24/7 for consultations on your present and future. Choose your guidance tool and preferred expert to transform your life. Whether it's Vastu, Tarot, Black Magic, or Planet remedies, Karmik Upaye has all occult sciences covered. Bring your problems, and we have the solutions.

            </p>
            <h2 style={{textAlign:"center"}}>About Occult Sciences:</h2>
            <p>
              The term "Occult Sciences" translates to "Gupt Vigyan" in Hindi, which means knowledge of hidden sciences. It refers to various disciplines that are followed not only in India but all over the world, such as Astrology, Numerology, Vastu, Tarot card reading, Healing, Reiki, and Angel Reading. These sciences were practiced by our ancestors around 5000 years ago for understanding events in the past and predicting the future.
              <br />   Even then, they had detailed knowledge about celestial bodies, including the distances between planets. Since these sciences are effective, why not utilize them to improve our future? By using them wisely, we can create a better tomorrow for ourselves.
            </p>

            <h2 style={{textAlign:"center"}}>What do we do for you at Karmik Upaye?</h2>
            <p>
              Karmik Upaye emphasizes transparency between the general public and the Acharya's/Experts because while people have faith in ancient science spanning over 5000 years, In today's era, trusting an expert for a life-changing solution can be challenging. To bridge this gap, Samadhana values transparency significantly. We have provided a platform for verified occult experts where they utilize their knowledge to resolve public issues. They aim to address problems comprehensively whether they pertain to marriage, children, career, health, or business. The platform ensures that experts make full use of their expertise to offer solutions in every possible way & That you don't run to multiple people, & websites seeking for a solution.
            </p>
            {/* <h1>
              <strong>Our Mission & Vision -</strong>
            </h1>
            <h2>
              <strong>Mission: -</strong>
            </h2>
            <p>
              Our mission is to provide accurate information to the public about
              their present and future for their betterment. This enables them
              to navigate life's forthcoming challenges with ease. Most
              importantly, it involves bringing forth the ancient wisdom that
              has been hidden over time, which is thousands of years old, but is
              gradually fading with the passage of time.
            </p>
            <h2>
              <strong>Vision: -</strong>
            </h2>
            <p>
              We aim to provide the correct solutions to those facing problems
              in any field of life. Through our experts, we strive to offer the
              public accurate guidance and steer them in the right direction.
            </p>
            <h2>
              <strong>Why Choose Karmik Upaye?</strong>
            </h2>
            <ul>
              <li>
                All-in-one Platform for all occult sciences consultation

              </li>
              <li>
                Easy Access to reach Astrologer
              </li>
              <li>
                Having team of knowledgeable Astrologer

              </li>
              <li>
                Here we assure you of premium quality predictions.
              </li>
            </ul> */}
          </Text>
        </ContentWithPaddingXl>
      </Container>

      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
