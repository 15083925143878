import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import AstrologyImage from "../../images/Homepage/Astrology.jpg";
import NumberologyImage from "../../images/Homepage/Numerology.jpg";
import VastuImage from "../../images/Homepage/Vastu_shastra.jpg";
import TaroReadingImage from "../../images/Homepage/Tarot card.jpg";
import { Link } from "react-router-dom";
import BackgroundAsImage from "components/hero/BackgroundAsImage";

const Container = tw.div`relative
`;
const Button = tw.button`mt-2 px-6 py-3 font-bold rounded bg-yellow-500 text-black hocus:bg-yellow-700 hocus:text-white  focus:shadow-outline focus:outline-none transition duration-300`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 px-6 flex`}
`;
const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs [box-shadow: 0 0 15px yellow] [transition: all .3s ease] hover:[transform: translateY(-10px)] items-center px-6 pt-4 bg-orange-900  pb-10 border-4 text-white border-solid border-yellow-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 w-[100px] h-[100px] border-yellow-500 text-center rounded-full overflow-hidden relative`}
    img {
      ${tw`w-[100px] h-[100px]`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-yellow-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      // imageSrc: 'https://plus.unsplash.com/premium_photo-1678308063685-eb6ef3fd7908?q=80&w=2128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      imageSrc: AstrologyImage,
      title: "Astrology",
      url: "/astrology",
      description: "Astrology is the mathematics of your life, derived from the calculations of your planetary positions to predict what the future holds for you.",
    },
    {
      imageSrc: VastuImage, title: "Vastu Shastra",
      url: "/vastu",
      description: "Vaastu Shastra, rooted in Vedic traditions, has been practiced for centuries to design auspicious and prosperous properties."
   
    },
    {
      imageSrc:  NumberologyImage, title: "Numerology",
      url: "/numerology",
      description: "Numerology is the belief in an occult, divine or mystical relationship between a number and one or more coinciding events. "
   
    },
    {
      imageSrc: TaroReadingImage, title: "Tarot Card Reading",
      url: "/tarot",
      description: "Tarot card reading is a form of cartomancy whereby practitioners use tarot cards to purportedly gain insight into the past, present or future."
      
    },
    // {
    //   imageSrc: FastIconImage, title: "Angle Reading",
    //   url: "/",
    //   description: "We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    // }
  ];

  return (
    <Container id="services">
      <ThreeColumnContainer>
        <Heading>Our Astrology <span tw="text-orange-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Link to={card.url} >
              <Card >
                <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>
                  <p className="description">
                    {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                  </p>
                </span>
                <Button>
                  <Link to={card.url}>
                    Book
                  </Link>
                </Button>
              </Card>
            </Link>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
