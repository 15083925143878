import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js";
import { Link } from "react-router-dom";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`sm:text-sm sm:mx-6`;

const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded  bg-yellow-600 text-gray-100
  hocus:bg-yellow-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;


const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 sm:text-lg lg:text-base xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-yellow-500 text-gray-100 hover:bg-yellow-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

export default ({
  imgurl, 
  navLinks = [
    <NavLinks key={1}>  <NavLink href="/">Home</NavLink>
    <NavLink href="/aboutus">About</NavLink>
    <NavLink href="/services">Services</NavLink>
    <NavLink href="/contact">Contact Us</NavLink>
    </NavLinks>,

  ],
  heading = (
    <>
      Find the perfect solution for you!
      <wbr />
      <br />
      <span tw="text-yellow-500">anywhere you go.</span>
    </>
  ),
  
  description,
  primaryActionUrl = "#",
  primaryActionText = "Sign Up",
  secondaryActionUrl = "/",
  secondaryActionText = "Search Hotels",
}) => {
  const RightColumn = styled.div`
  background-image: url(${imgurl});
  // background-image: url("https://img.freepik.com/free-vector/zodiac-circle-with-horoscope-signs-fish-pisces-scorpio-aquarius-zodiak-aries-virgo-vector-illustration_1284-46992.jpg?w=740&t=st=1705406846~exp=1705407446~hmac=c34e70ba3edf73ac830b11d133397dcb471b3de8ee050099075fdc15b8af5bd0");
  ${tw`bg-yellow-500 bg-cover bg-center xl:ml-24 h-[30rem] md:h-auto [background: fixed no-repeat top / center cover] w-full lg:w-1/2 lg:flex-1`}
`;
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
              <a href={secondaryActionUrl} className="action secondaryAction">
                {secondaryActionText}
              </a>
            </Actions>
          </Content>
        </LeftColumn>
        <RightColumn >
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};
