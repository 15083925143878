import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent";
import Features from "components/features/DashedBorderSixFeatures";
import Featuress from "components/features/ThreeColSimple.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from 'components/footers/SimpleFiveColumn';

const HighlightedText = tw.span`text-orange-500`

export default () => {
  return (
    <>
    <AnimationRevealPage disabled>
    <Hero />
    
      <Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
        />
      {/* <MainFeature
        heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
        />
      <FeatureStats/> */}
      <Testimonial 
        heading={<> <HighlightedText>Testimonials</HighlightedText></>}
        />
        <Featuress/>
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
        />
        <Footer />
    </AnimationRevealPage>
        </>
      
  );
}
